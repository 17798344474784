export default /**
 * File generated by js-routes 2.3.0
 * Based on Rails 7.2.2.1 routes of Robis::Application
 */
// eslint-disable-next-line
(typeof window !== 'undefined' ? window : this).Routes = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const UnescapedSpecials = "-._~!$&'()*+,;=:@"
        .split("")
        .map((s) => s.charCodeAt(0));
    const UnescapedRanges = [
        ["a", "z"],
        ["A", "Z"],
        ["0", "9"],
    ].map((range) => range.map((s) => s.charCodeAt(0)));
    const ModuleReferences = {
        CJS: {
            define(routes) {
                if (module) {
                    module.exports = routes;
                }
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                if (define) {
                    define([], function () {
                        return routes;
                    });
                }
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using (typeof window !== 'undefined' ? window : this).Routes = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (false &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            if (keyword_parameters.anchor) {
                result += "#" + keyword_parameters.anchor;
            }
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            if (segment.match(/^[a-zA-Z0-9-]$/)) {
                // Performance optimization for 99% of cases
                return segment;
            }
            return (segment.match(/./gu) || [])
                .map((ch) => {
                const code = ch.charCodeAt(0);
                if (UnescapedRanges.find((range) => code >= range[0] && code <= range[1]) ||
                    UnescapedSpecials.includes(code)) {
                    return ch;
                }
                else {
                    return encodeURIComponent(ch);
                }
            })
                .join("");
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return `(${this.build_path_spec(route[1])})`;
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
            return module;
        }
    }
    const utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return utils.route(parts_table, route_spec, absolute);
        },
    };
    return utils.define_module("NIL", {
        ...__jsr,
        configure: (config) => {
            return utils.configure(config);
        },
        config: () => {
            return utils.config();
        },
        serialize: (object) => {
            return utils.serialize(object);
        },
        ...{
  /**
   * Generates rails route to
   * /stocks/active_liquid_containers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  active_liquid_containers_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"active_liquid_containers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/active_plate_containers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  active_plate_containers_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"active_plate_containers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/active(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  active_replenishment_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[6,"active"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/active_solid_containers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  active_solid_containers_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"active_solid_containers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/alergens/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_alergen_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alergens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/alergens(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_alergens_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alergens"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/boiler_batch_snapshots/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_boiler_batch_snapshot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"boiler_batch_snapshots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/boiler_batch_snapshots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_boiler_batch_snapshots_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"boiler_batch_snapshots"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/configurator_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_configurator_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"configurator_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/configurator_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_configurator_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"configurator_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/cooking_modes/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_cooking_mode_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cooking_modes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/cooking_modes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_cooking_modes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cooking_modes"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/currencies(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_currencies_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"currencies"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/currencies/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_currency_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"currencies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/custom_vision(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_custom_vision_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"custom_vision"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/external_api_webhook_logs(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_external_api_webhook_logs_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"external_api_webhook_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/fractions/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_fraction_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fractions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/fractions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_fractions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fractions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/groups/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_group_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/groups(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_groups_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"groups"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/locations(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_locations_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/main_batch_snapshots/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_main_batch_snapshot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"main_batch_snapshots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/main_batch_snapshots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_main_batch_snapshots_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"main_batch_snapshots"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/menu_categories(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_menu_categories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"menu_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/menu_categories/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_menu_category_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"menu_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/monitor(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_monitor_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"monitor"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/roles(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_roles_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/scheduler_run_reports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_scheduler_run_reports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"scheduler_run_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/settings/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_setting_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/settings(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_settings_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/units/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_unit_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/units(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_units_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/users(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/web_request_logs(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  admin_web_request_logs_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"web_request_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_attributes/alergens(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  alergens_product_attributes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_attributes"],[2,[7,"/"],[2,[6,"alergens"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/config(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_config_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"config"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/configurator/orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_configurator_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"configurator"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/configurator/prices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_configurator_prices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"configurator"],[2,[7,"/"],[2,[6,"prices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/menus(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_external_v1_menus_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"menus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_external_v1_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_external_v1_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/recipes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_external_v1_recipes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"recipes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/health(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_health_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"health"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/attachments/:id/:attachable_type/:attachable_id(.:format)
   * @param {any} id
   * @param {any} attachable_type
   * @param {any} attachable_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_attachment_path: __jsr.r({"id":{"r":true},"attachable_type":{"r":true},"attachable_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"attachable_type"],[2,[7,"/"],[2,[3,"attachable_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/locations/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_location_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/menus/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_menu_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"menus"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/products/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_product_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/products(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_products_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/recipes/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_recipe_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/recipes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_recipes_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"recipes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/roles/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_role_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/roles(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_roles_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/users/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/users(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/values/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_internal_value_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"values"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/auth(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_mobile_v1_auth_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_mobile_v1_order_path: __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/register_and_auth(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_mobile_v1_register_and_auth_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"register_and_auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/me(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_service_v1_me_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/product_replenishments(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_service_v1_product_replenishments_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"product_replenishments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/verifications(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_service_v1_verifications_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"verifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/available_time_slots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  available_time_slots_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"available_time_slots"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/work_batch/boiler_batch_status(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  boiler_batch_status_api_internal_work_batch_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"work_batch"],[2,[7,"/"],[2,[6,"boiler_batch_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/book_time_slot(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  book_time_slot_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"book_time_slot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/orders/:id/cancel(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_api_external_v1_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders/:id/cancel_manual_payment(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_manual_payment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_manual_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders/:id/cancel_manually_paid_payment(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_manually_paid_payment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_manually_paid_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/cancel_processing_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_processing_order_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cancel_processing_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_replenishments/:id/cancel(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_product_replenishment_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"product_replenishments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /queued_orders/:id/cancel(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_queued_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"queued_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/cancel_time_slot(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_time_slot_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cancel_time_slot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/cancel_timeslot_and_payment(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cancel_timeslot_and_payment_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"cancel_timeslot_and_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/cart(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cart_replenishment_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[6,"cart"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/authorization/check(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  check_api_service_v1_authorization_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"authorization"],[2,[7,"/"],[2,[6,"check"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/service_sessions/check(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  check_api_service_v1_service_sessions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"service_sessions"],[2,[7,"/"],[2,[6,"check"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/clear_draft_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  clear_draft_order_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"clear_draft_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /configurator
   * @param {object | undefined} options
   * @returns {string} route path
   */
  configurator_path: __jsr.r({}, [2,[7,"/"],[6,"configurator"]]),

  /**
   * Generates rails route to
   * /configurator/
   * @param {object | undefined} options
   * @returns {string} route path
   */
  configurator_root_path: __jsr.r({}, [2,[2,[7,"/"],[6,"configurator"]],[7,"/"]]),

  /**
   * Generates rails route to
   * /orders/:id/confirm_manual_payment(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  confirm_manual_payment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_manual_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/cooking_units(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cooking_units_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"cooking_units"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /recipes/:id/create_new_version_draft(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  create_new_version_draft_recipe_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_new_version_draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders/create_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  create_order_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"create_order"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/users/create_payment_method(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  create_payment_method_api_mobile_v1_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"create_payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/menus/current_day_active_menu(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  current_day_active_menu_api_mobile_v1_menus_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"menus"],[2,[7,"/"],[2,[6,"current_day_active_menu"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /delayed_job
   * @param {object | undefined} options
   * @returns {string} route path
   */
  delayed_job_web_path: __jsr.r({}, [2,[7,"/"],[6,"delayed_job"]]),

  /**
   * Generates rails route to
   * /users/sign_out(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  destroy_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /products/:id/details(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  details_product_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/draft_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  draft_order_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"draft_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /dynamic_tables/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  dynamic_table_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dynamic_tables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/alergens/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_alergen_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alergens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/cooking_modes/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_cooking_mode_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cooking_modes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/currencies/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_currency_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"currencies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/fractions/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_fraction_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fractions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/groups/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_group_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/locations/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_location_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/menu_categories/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_menu_category_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"menu_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/monitor/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_monitor_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"monitor"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/roles/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_role_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/units/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_unit_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/users/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_admin_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_product_category_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /recipes/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_recipe_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_stock_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/orders/:id/enqueue(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  enqueue_api_external_v1_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enqueue"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/enter_edit_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  enter_edit_order_replenishment_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[6,"enter_edit_order"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/service_sessions/finish(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finish_api_service_v1_service_sessions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"service_sessions"],[2,[7,"/"],[2,[6,"finish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_attributes/fractions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fractions_product_attributes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_attributes"],[2,[7,"/"],[2,[6,"fractions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_attributes/groups(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  groups_product_attributes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_attributes"],[2,[7,"/"],[2,[6,"groups"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/historical_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  historical_orders_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"historical_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders/history_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  history_orders_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"history_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/history(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  history_replenishment_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/work_batch/lockers_status(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lockers_status_api_internal_work_batch_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"work_batch"],[2,[7,"/"],[2,[6,"lockers_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/work_batch/main_batch_status(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  main_batch_status_api_internal_work_batch_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"work_batch"],[2,[7,"/"],[2,[6,"main_batch_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders/:id/mark_as_delivered(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_as_delivered_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_delivered"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/mark_as_manual_payment(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_as_manual_payment_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"mark_as_manual_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/mark_as_ready_for_capture(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_as_ready_for_capture_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"mark_as_ready_for_capture"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders/mark_as_ready_for_capture(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_as_ready_for_capture_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"mark_as_ready_for_capture"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/product_replenishments/:id/mark_as_replaced(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_as_replaced_api_service_v1_product_replenishment_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"product_replenishments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_replaced"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/settings/mark_robot_as_reset(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_robot_as_reset_admin_settings_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"mark_robot_as_reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/recipes/menu_categories(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  menu_categories_api_internal_recipes_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[6,"menu_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/menus/menu_changed(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  menu_changed_api_internal_menus_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"menus"],[2,[7,"/"],[2,[6,"menu_changed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /menu(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  menu_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"menu"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/internal/menus/merge(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  merge_api_internal_menus_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"menus"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/:id/merge_draft_to_sent_order(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  merge_draft_to_sent_order_replenishment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge_draft_to_sent_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/alergens/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_alergen_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alergens"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/cooking_modes/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_cooking_mode_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cooking_modes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/currencies/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_currency_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"currencies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/fractions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_fraction_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fractions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/groups/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_group_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/menu_categories/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_menu_category_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"menu_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/monitor/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_monitor_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"monitor"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/roles/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_role_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/units/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_unit_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/users/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_admin_user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_product_category_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_rails_conductor_inbound_email_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_rails_conductor_inbound_email_source_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /recipes/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_recipe_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_stock_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/sign_in(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/external/v1/lockers/:number/open(.:format)
   * @param {any} number
   * @param {object | undefined} options
   * @returns {string} route path
   */
  open_api_external_v1_locker_path: __jsr.r({"number":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lockers"],[2,[7,"/"],[2,[3,"number"],[2,[7,"/"],[2,[6,"open"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /queued_orders/:id/open_locker(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  open_locker_queued_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"queued_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"open_locker"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/users/payment_methods(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  payment_methods_api_mobile_v1_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"payment_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/:id/place_order(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  place_order_replenishment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"place_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /plate_containers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  plate_containers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plate_containers"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /products/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_categories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /stocks/product_categories(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_categories_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"product_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/product_categories_with_products(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_categories_with_products_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"product_categories_with_products"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_category_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /products/product_columns(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_columns_products_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"product_columns"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_containers/:product_container_id/remove(.:format)
   * @param {any} product_container_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_container_remove_path: __jsr.r({"product_container_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"product_containers"],[2,[7,"/"],[2,[3,"product_container_id"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_containers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_containers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_containers"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /stocks/product(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  product_stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[6,"product"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /products(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  products_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /products/products_with_categories(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  products_with_categories_products_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"products_with_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /admin/custom_vision/projects(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  projects_admin_custom_vision_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"custom_vision"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
   * @param {any} signed_blob_id
   * @param {any} variation_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_blob_representation_path: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
   * @param {any} signed_blob_id
   * @param {any} variation_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_blob_representation_proxy_path: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
   * @param {any} inbound_email_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_incinerate_path: __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
   * @param {any} inbound_email_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_reroute_path: __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_sources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/direct_uploads(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_direct_uploads_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/disk/:encoded_key/*filename(.:format)
   * @param {any} encoded_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_disk_service_path: __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mailgun_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mandrill/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mandrill_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mandrill/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mandrill_inbound_health_check_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/postmark/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_postmark_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/relay/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_relay_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_sendgrid_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
   * @param {any} signed_id
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_service_blob_path: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
   * @param {any} signed_id
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_service_blob_proxy_path: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /recipes/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  recipe_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /recipes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  recipes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /recipes/recipes_columns(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  recipes_columns_recipes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[6,"recipes_columns"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /microsoft/refresh_token(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  refresh_token_microsoft_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"microsoft"],[2,[7,"/"],[2,[6,"refresh_token"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  replenishment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders/:id/replenishment_order_pdf(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  replenishment_order_pdf_replenishment_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"replenishment_order_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /replenishment_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  replenishment_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"replenishment_orders"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /reports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  reports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /orders/reset_queue(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  reset_queue_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"reset_queue"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/work_batch/reset_robot(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  reset_robot_api_internal_work_batch_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"work_batch"],[2,[7,"/"],[2,[6,"reset_robot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /
   * @param {object | undefined} options
   * @returns {string} route path
   */
  root_path: __jsr.r({}, [7,"/"]),

  /**
   * Generates rails route to
   * /product_categories/roots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  roots_product_categories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[6,"roots"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api-docs
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rswag_api_path: __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]),

  /**
   * Generates rails route to
   * /api-docs
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rswag_ui_path: __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]),

  /**
   * Generates rails route to
   * /products/search(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  search_products_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories/select_options(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  select_options_product_categories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[6,"select_options"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/users/set_default_payment_method(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_default_payment_method_api_mobile_v1_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"set_default_payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /recipes/:id/low_level(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  show_low_level_recipe_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"low_level"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/service_sessions/start(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  start_api_service_v1_service_sessions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"service_sessions"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /stocks/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  stock_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /stocks(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  stocks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stocks"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /users/stripe_3d_secure_callback(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  stripe_3d_secure_callback_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"stripe_3d_secure_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/stripe_set_card_as_default(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  stripe_set_card_as_default_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"stripe_set_card_as_default"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/work_batch/system_status(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  system_status_api_internal_work_batch_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"work_batch"],[2,[7,"/"],[2,[6,"system_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_attributes/units(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  units_product_attributes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_attributes"],[2,[7,"/"],[2,[6,"units"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /dynamic_tables/update_all(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_all_dynamic_tables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dynamic_tables"],[2,[7,"/"],[2,[6,"update_all"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/mobile/v1/orders/update_draft_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_draft_order_api_mobile_v1_orders_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"update_draft_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /dynamic_tables/update_positions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_positions_dynamic_tables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dynamic_tables"],[2,[7,"/"],[2,[6,"update_positions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories/update_positions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_positions_product_categories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[6,"update_positions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/disk/:encoded_token(.:format)
   * @param {any} encoded_token
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_rails_disk_service_path: __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /product_categories/update_table_positions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_table_positions_product_categories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"product_categories"],[2,[7,"/"],[2,[6,"update_table_positions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /users/sign_in(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/service/v1/product_replenishments/:id/verify(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  verify_api_service_v1_product_replenishment_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"service"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"product_replenishments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/settings/wash_boiler(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  wash_boiler_admin_settings_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"wash_boiler"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /admin/settings/wash_gripper(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  wash_gripper_admin_settings_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"wash_gripper"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/internal/menus/:month(.:format)
   * @param {any} month
   * @param {object | undefined} options
   * @returns {string} route path
   */
  within_month_api_internal_menus_path: __jsr.r({"month":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"menus"],[2,[7,"/"],[2,[3,"month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /recipes/:id/execute(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  write_low_level_recipe_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"recipes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"execute"],[1,[2,[8,"."],[3,"format"]]]]]]]]])}
,
    });
})();

