import { registerComponent } from './startup'

registerComponent('MonitorScreen', () => import('./components/monitor_screen'))
registerComponent('UsersEditScreen', () => import('./components/users/UsersEditScreen'))
registerComponent('LocationEditScreen', () => import('./components/locations/LocationEditScreen'))
registerComponent('Recipes', () => import('./components/recipes'))
registerComponent('RecipesEditScreen', () => import('./components/recipes/RecipesEditScreen'))
registerComponent('MenuTable', () => import('./components/menus/menuTable'))
registerComponent('Orders', () => import('./components/orders'))
registerComponent('OrderModal', () => import('./components/orders/orderModal'))
registerComponent('Reports', () => import('./components/reports'))
registerComponent('ProductCategoriesIndex', () => import('./components/product_categories/ProductCategoriesIndex'))
registerComponent('StockControl', () => import('./components/stock_control'))
registerComponent('ConfiguratorOrders', () => import('./components/configurator/orders'))
registerComponent('ReplenishmentNotice', () => import('./components/shared/ReplenishmentNotice'))
registerComponent('EmptyTablePlaceholder', () => import('./components/shared/EmptyTablePlaceholder'))
registerComponent('SchedulerRunReportsIndex', () => import('./components/admin/scheduler_run_reports'))
registerComponent('ExternalApiWebhookLogs', () => import('./components/admin/external_api_webhook_logs'))
registerComponent('CustomVision', () => import('./components/admin/custom_vision'))
registerComponent('RolesEditScreen', () => import('./components/roles/RolesEditScreen'))
