import React from 'react'
import setupAxios from './config/setupAxios'
import setupMoment from './config/setupMoment'
import Routes from './config/routes.js.erb'
import each from 'lodash/each'
import { createRoot } from 'react-dom/client'

export default function() {
  setupAxios()
  setupMoment()

  window.addEventListener('DOMContentLoaded', setupAxios)
  window.Routes = Routes
}

const mountedNodeRoots = new Map()
const mountables = {}

export function registerComponent(name, importFunction) {
  mountables[name] = importFunction
}

document.addEventListener('DOMContentLoaded', () => {
  each(document.querySelectorAll('[data-react-class]'), (node) => {
    const importFunction = mountables[node.dataset.reactClass]
    if (importFunction) {
      importFunction().then(({ default: Component }) => {
        const props = JSON.parse(node.dataset.reactProps || '{}')
        let root = mountedNodeRoots.get(node)

        if (!root) {
          root = createRoot(node)
          mountedNodeRoots.set(node, root)
        }

        root.render(
          <Component {...props} />
        )
      })
    }
  })
})
